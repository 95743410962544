import { format, isAfter, isBefore, parse } from "date-fns";

function customPrompt(props) {
  const { toValue, fromValue, destination, isRestaurants, tripType, dietaryPreferences, anyThingElse, interest, time } = props;

  const selectedInterest = interest?.filter((int) => int?.isSelected);

  const formattedDestination = destination.map((des) => des.address).join(" to ");
  const formattedTripType = tripType?.map((type) => tripTypeCondition[type]).join(", ");
  const formattedStartTime = time.startTime ? format(time.startTime, "hh:mm a") : "";
  const formattedEndTime = time.endTime ? format(time.endTime, "hh:mm a") : "";
  const formattedDietaryPreferences = dietaryPreferences.join(", ");
  const formattedInterest = selectedInterest?.map((int) => int.label).join(", ");
  const formatString = 'hh:mm a';
  const parsedStartTime = parse(formattedStartTime, formatString, new Date());
  const parsedEndTime = parse(formattedEndTime, formatString, new Date());
  const noonTime = parse('12:00 PM', formatString, new Date());
  const eveningTime = parse('07:00 PM', formatString, new Date());

  // start time 
  const isAfterNoon = isAfter(parsedStartTime, noonTime);
  const isEvening = isAfter(parsedStartTime, eveningTime);
  
  // end time
  
  const isEndAfterNoon = isBefore(parsedEndTime, noonTime);
  const isEndEvening = isBefore(parsedEndTime, eveningTime);






//   const promptForItinerary = `
//   Create a detailed itinerary for a trip to ${formattedDestination}. We will land on ${fromValue} and depart on ${toValue}.${formattedStartTime && formattedEndTime ? `Start my trip at ${formattedStartTime} and ends on ${formattedEndTime}.` : ""}${tripType?.length ? `The trip is categorized as ${formattedTripType}.` : ""}The goal is to see all the best and most iconic places. ${tripType?.length ? `This is a ${tripType.join(", ")} trip, so tailor the itinerary to these preferences.` : ""}.Include suggestions for local places for breakfast, lunch, and dinner, specifying the name and address of each restaurant or cafe.${dietaryPreferences.length ? `Suggest ${formattedDietaryPreferences} food options.` : ""}${selectedInterest?.length ? `I have an interest in ${formattedInterest}.` : ""}Each day maximum eight activities scheduled in the following strict chronological order:
// - Breakfast 
// - First activity 
// - Second activity 
// - Lunch 
// - Third activity 
// - Fourth activity 
// - Dinner 
// - Final activity
//  ${anyThingElse}

//  ${formattedStartTime && formattedEndTime ? `Strictly adjust the activity schedule based on the trip start and end times:
// - If the trip start time is later in the day, skip activities that occur before the start time on the first day. For example, if the trip starts at 3:00 PM, skip breakfast and morning activities on the first day.
// - If the trip end time is earlier in the day, ensure that all activities fit within the specified time frame on the last day, even if it means reducing the number of activities. For example, if the trip ends at 3:00 PM, adjust the schedule to include only activities that can fit before 3:00 PM.

// Manage the activities based on the trip start time of ${formattedStartTime} and the trip end time of ${formattedEndTime}. Provide a clear and concise format, and strictly adhere to the order and time constraints. The itinerary must follow the instructions provided.
// ` : ""}`;

// const promptForItinerary = `
// Create a detailed itinerary for a trip to ${formattedDestination}. We will land on ${fromValue} and depart on ${toValue}.${formattedStartTime && formattedEndTime ? ` The trip starts at ${formattedStartTime} and ends at ${formattedEndTime}.` : ""} ${tripType?.length ? `The trip is categorized as ${formattedTripType}.` : ""} The goal is to see all the best and most iconic places. ${tripType?.length ? `This is a ${tripType.join(", ")} trip, so tailor the itinerary to these preferences.` : ""} Include suggestions for local places for breakfast, lunch, and dinner, specifying the name and address of each restaurant or cafe.${dietaryPreferences.length ? ` Suggest ${formattedDietaryPreferences} food options.` : ""} ${selectedInterest?.length ? `I have an interest in ${formattedInterest}.` : ""}

// Each day should include a maximum of eight activities scheduled in the following strict chronological order:
// - Breakfast 
// - First activity 
// - Second activity 
// - Lunch 
// - Third activity 
// - Fourth activity 
// - Dinner 
// - Final activity

// ${anyThingElse}

// ${formattedStartTime && formattedEndTime ? `
// Adjust the schedule strictly based on the trip start and end times:
// - On the first day, start the first activity at ${formattedStartTime} and skip all previous activities (e.g., breakfast, morning activities, lunch) if the start time is later in the day. Begin scheduling from the first activity that fits within the time frame after ${formattedStartTime}.
// - On the last day, ensure all activities fit before the end time at ${formattedEndTime}, skipping any activities that would occur after this time. For example, if the trip ends at 15:00 (3:00 PM), adjust the schedule to include only activities that can fit before 15:00.

// Detailed Instructions for the First Day:
// - Start the first activity exactly at ${formattedStartTime}.
// - Skip breakfast, morning activities, and lunch if the start time is later than these meals.
// - Schedule the first activity at ${formattedStartTime}, followed by subsequent activities in the given order.

// Detailed Instructions for the Last Day:
// - Ensure all activities fit within the time frame before ${formattedEndTime}.
// - Skip dinner and evening activities if the end time is earlier than these meals.
// - Adjust the schedule to include only activities that fit before ${formattedEndTime}.

// Manage the activities based on the trip start time of ${formattedStartTime} and the trip end time of ${formattedEndTime}. Provide a clear and concise format, and strictly adhere to the order and time constraints. The itinerary must follow the instructions provided.` : ""}
// `;


// const promptForItinerary = `
// Create a detailed itinerary for a trip to ${formattedDestination}. We will land on ${fromValue} and depart on ${toValue}. ${formattedStartTime && formattedEndTime ? `The trip starts at ${formattedStartTime} and ends at ${formattedEndTime}.` : ""} ${tripType?.length ? `The trip is categorized as ${formattedTripType}.` : ""} The goal is to see all the best and most iconic places. ${tripType?.length ? `This is a ${tripType.join(", ")} trip, so tailor the itinerary to these preferences.` : ""} Include suggestions for local places for breakfast, lunch, and dinner, specifying the name and address of each restaurant or cafe.${dietaryPreferences.length ? ` Suggest ${formattedDietaryPreferences} food options.` : ""} ${selectedInterest?.length ? `I have an interest in ${formattedInterest}.` : ""}

// ${formattedStartTime && formattedEndTime ? `
//   For First Day:
// - Strictly Start the first activity exactly at ${formattedStartTime}.
// - Skip breakfast, morning activities, and lunch if the start time is later than these meals.
// - Begin scheduling from the first activity that fits within the time frame after ${formattedStartTime}.

//  For Last Day:
// - Ensure all activities fit within the time frame before ${formattedEndTime}.
// - Skip dinner and evening activities if the end time is earlier than these meals.
// - Adjust the schedule to include only activities that fit before ${formattedEndTime}.

// ### Other Days:
// Each day should include a maximum of eight activities scheduled in the following strict chronological order:
// - Breakfast 
// - First activity 
// - Second activity 
// - Lunch 
// - Third activity 
// - Fourth activity 
// - Dinner 
// - Final activity

// Manage the activities based on the trip start time of ${formattedStartTime} and the trip end time of ${formattedEndTime}. Provide a clear and concise format, and strictly adhere to the order and time constraints. The itinerary must follow the instructions provided.` : `Each day should include a maximum of eight activities scheduled in the following strict chronological order:
// - Breakfast 
// - First activity 
// - Second activity 
// - Lunch 
// - Third activity 
// - Fourth activity 
// - Dinner 
// - Final activity
// `}
// ${anyThingElse}
// `;

const promptForItinerary = `
Create a detailed itinerary for a trip to ${formattedDestination}. We will land on ${fromValue} and depart on ${toValue}. 
${formattedStartTime && formattedEndTime ? `The trip starts at ${formattedStartTime} and ends at ${formattedEndTime}.` : ""} 
All times mentioned in UTC 0.${tripType?.length ? `The trip is categorized as ${formattedTripType}.` : ""} The goal is to see all the best and most iconic places. 
${tripType?.length ? `This is a ${tripType.join(", ")} trip, so tailor the itinerary to these preferences.` : ""} 
Include suggestions for local places for breakfast, lunch, and dinner, specifying the name and address of each restaurant or cafe. 
${dietaryPreferences.length ? `Suggest ${formattedDietaryPreferences} food options.` : ""} 
${selectedInterest?.length ? `I have an interest in ${formattedInterest}.` : ""}
${formattedStartTime && formattedEndTime ? `
  For the First Day:
  - Start the first activity exactly at ${formattedStartTime}.
  ${isAfterNoon && !isEvening ? "- Skip breakfast and morning activities." : ""}  
  ${isEvening ? "- Skip breakfast, lunch and morning,afternoon activities." : "" }
  - Begin scheduling from the first activity that fits within the time frame after ${formattedStartTime}.
  
  For the Last Day:
  - End the last activity exactly at ${formattedEndTime}.
  ${isEndAfterNoon && isEndEvening  ? "- Add only breakfast and morning activities. Skip lunch and etc" : ""} 
  ${!isEndAfterNoon && isEndEvening  ? `- Add breakfast and lunch activities and skip activities that after ${formattedEndTime}.` : ""} 
  ${!isEndEvening ? `-Add breakfast , lunch , afternoon activities` : ""}  
  - Adjust the schedule to include only activities that fit before ${formattedEndTime}.

### Other Days:
Each day should include a maximum of eight activities scheduled in the following strict chronological order:
  - Breakfast
  - First activity
  - Second activity
  - Lunch
  - Third activity
  - Fourth activity
  - Dinner
  - Final activity

Manage the activities based on the trip start time of ${formattedStartTime} and the trip end time of ${formattedEndTime}. Provide a clear and concise format, and strictly adhere to the order and time constraints. The itinerary must follow the instructions provided.` : `
Each day should include a maximum of eight activities scheduled in the following strict chronological order:
  - Breakfast
  - First activity
  - Second activity
  - Lunch
  - Third activity
  - Fourth activity
  - Dinner
  - Final activity
`}
${anyThingElse}
`;

console.log(promptForItinerary)


  return promptForItinerary;
}
// ${formattedStartTime && formattedEndTime ? `Ensure that activities fit within the specified time frame from ${formattedStartTime} to ${formattedEndTime}` : ""}.

export default customPrompt;

const tripTypeCondition = {
  family: "for a family",
  romantic: "for a couple",
  cultural: "for cultural",
  adventure: "for exciting adventure",
  party: "for party",
};
